.sketch-display {
  min-height: 100vh;
}

.sketch-header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.canvas-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.canvas-container {
  position: relative;
}
