@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/Poppins-Bold.ttf");
}

.firebase-emulator-warning {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", "Roboto", "default";
  font-weight: 500;
}

p,
a {
  font-family: "Poppins", "Roboto", "default";
}
